import React, { useState } from "react";
import {
  Box,
  Stack,
  Button,
  DialogTitle,
  DialogActions,
  Dialog,
  CircularProgress,
} from "@mui/material";
import { humanResolve } from "../../services/api";
import { useApp } from "../../contexts/AppContext";

const ResolverActions = ({ selectedResolvers, refetch, events }) => {
  if (selectedResolvers.length === 0) return null;
  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const { user, resolverFilter, updateResolverFilters } = useApp();

  const resolvableEvents = selectedResolvers?.filter((eventId) => {
    const event = events?.find((e) => e.event_id === eventId);
    return event && event.answer !== true && event.answer !== false;
  });

  const handleResolveClick = () => {
    if (resolvableEvents.length > 0) {
      setOpenDialog(true);
    } else {
      alert("No valid events selected for resolution.");
    }
  };

  const handleResolution = async (resolution) => {
    setLoading(true);
    try {
      await humanResolve(resolvableEvents, resolution);
      setOpenDialog(false);
      refetch();
    } catch (error) {
      console.error("Failed to resolve:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Box sx={{ mb: 3 }}>
      <Stack direction="row" spacing={1}>
          {user &&
            user?.user_metadata?.acl?.resolve_events &&
            resolvableEvents.length > 0 && (
              <Button
                variant="outlined"
                size="small"
                onClick={handleResolveClick}
                disabled={resolvableEvents.length === 0}
              >
                Resolve Selected ({resolvableEvents.length})
              </Button>
            )}
        </Stack>
      </Box>

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "1.5em",
            fontWeight: "bold",
          }}
        >
          Set resolution for {resolvableEvents.length} events:
        </DialogTitle>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-evenly",
            padding: "24px 24px",
          }}
        >
          <Button
            variant="contained"
            color="success"
            onClick={() => handleResolution(true)}
            disabled={loading}
          >
            {loading ? <CircularProgress size={20} /> : "✅"}
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => handleResolution(false)}
            disabled={loading}
          >
            {loading ? <CircularProgress size={20} /> : "❌"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ResolverActions;
