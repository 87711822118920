import React, { createContext, useContext, useState, useCallback, useEffect } from "react";
import { api } from "../services/api";

const AppContext = createContext();

const defaultFilters = {
  events: {
    reaction: null,
    issue: null,
    status: null,
    event_time_frame: null,
    date: null,
    country: null,
    controversy_range: null,
    popularity_range: null,
    probability_range: null,
    created_at_filter: null,
    published: null,
  },
  inputs: {
    type: null,
    category: null,
    status: null,
  },
  has_comments: null,
  has_reasoning: null,
};

const defaultResolverFilters = {
  has_resolution: true,
  perplexity_false_positive: null,
  serper_false_positive: null,
  search: "",
  published: null,
};

const defaultPreferences = {
  darkMode: true,
  tablePageSize: 25,
  dateFormat: "MMM DD, YYYY HH:mm:ss",
  expandedSections: new Set(),
};

export function AppProvider({ children }) {
  //Auth
  const [user, setUser] = useState(null);

  // Theme preferences
  const [darkMode, setDarkMode] = useState(defaultPreferences.darkMode);

  // User preferences
  const [preferences, setPreferences] = useState(defaultPreferences);

  // Filters state
  const [filters, setFilters] = useState(defaultFilters);

  // Filter Resolver state
  const [resolverFilter, setResolverFilters] = useState(defaultResolverFilters);

  // UI State
  const [selectedItems, setSelectedItems] = useState(new Set());
  const [expandedSections, setExpandedSections] = useState(new Set());

  // Loading and error states
  const [globalLoading, setGlobalLoading] = useState(false);
  const [globalError, setGlobalError] = useState(null);

  // Notifications
  const [notifications, setNotifications] = useState([]);


  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
  }, []);

  useEffect(() => {
    if (user?.expiresAt) {
      const timeUntilExpiration = user.expiresAt - Date.now();
  
      if (timeUntilExpiration > 0) {
        const maxTimeout = 2_147_483_647;
        const timeout = setTimeout(() => {
          logoutUser();
          alert("Your session has expired. Please log in again.");
        }, Math.min(timeUntilExpiration, maxTimeout));
  
        return () => clearTimeout(timeout);
      } else {
        logoutUser();
      }
    }
  }, [user]);

  // Handlers
  const toggleDarkMode = useCallback(() => {
    setDarkMode((prev) => !prev);
  }, []);

  const updatePreference = useCallback((key, value) => {
    setPreferences((prev) => ({
      ...prev,
      [key]: value,
    }));
  }, []);

  const updateFilters = useCallback((listType, newFilters) => {
    setFilters((prev) => ({
      ...prev,
      [listType]: {
        ...prev[listType],
        ...newFilters,
      },
    }));
  }, []);

  const updateResolverFilters = useCallback((newFilters) => {
    setResolverFilters((prev) => ({
      ...prev,
      ...newFilters,
    }));
  }, []);

  const toggleSelectedItem = useCallback((itemId) => {
    setSelectedItems((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(itemId)) {
        newSet.delete(itemId);
      } else {
        newSet.add(itemId);
      }
      return newSet;
    });
  }, []);

  const toggleExpandedSection = useCallback((sectionId) => {
    setExpandedSections((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(sectionId)) {
        newSet.delete(sectionId);
      } else {
        newSet.add(sectionId);
      }
      return newSet;
    });
  }, []);

  const addNotification = useCallback((message, type = "info") => {
    const id = Date.now();
    setNotifications((prev) => [...prev, { id, message, type }]);
    setTimeout(() => {
      removeNotification(id);
    }, 5000);
  }, []);

  const removeNotification = useCallback((id) => {
    setNotifications((prev) =>
      prev.filter((notification) => notification.id !== id)
    );
  }, []);

  const clearError = useCallback(() => {
    setGlobalError(null);
  }, []);

  const loginUser = useCallback((userData) => {
    setUser(userData);
    localStorage.setItem(
      "user",
      JSON.stringify({
        ...userData,
        expiresAt: new Date(userData.expires_at).getTime(),
      })
    );
    if (userData?.token) {
      api.defaults.headers.Authorization = `Bearer ${userData.token}`;
    }
  }, []);

  const logoutUser = useCallback(() => {
    setUser(null);
    localStorage.removeItem("user"); 
    delete api.defaults.headers.Authorization;
  }, []);

  const value = {
    // Theme
    darkMode,
    toggleDarkMode,

    // Preferences
    preferences,
    updatePreference,

    // Filters
    filters,
    updateFilters,

    // Resolver Filters
    resolverFilter,
    updateResolverFilters,

    // UI State
    selectedItems,
    toggleSelectedItem,
    expandedSections,
    toggleExpandedSection,

    // Loading and Error
    globalLoading,
    setGlobalLoading,
    globalError,
    setGlobalError,
    clearError,

    // Notifications
    notifications,
    addNotification,
    removeNotification,

    // Auth
    user,
    loginUser,
    logoutUser,
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}

export function useApp() {
  const context = useContext(AppContext);
  if (context === undefined) {
    throw new Error("useApp must be used within an AppProvider");
  }
  return context;
}
