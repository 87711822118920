import React from "react";
import {
  TableRow,
  TableCell,
  IconButton,
  Chip,
  Box,
  Typography,
} from "@mui/material";
import {
  ContentCopy,
  Twitter,
  Google,
  Feed,
  OpenInNew,
  StarBorder,
  FormatListNumbered,
} from "@mui/icons-material";
import TruncatedText from "./TruncatedText";
import { suitabilityColors, suitabilityLabels } from "./utils";

const transformToJSON = (input) => {
  const jsonObject = {};
  input.split(", ").forEach((pair) => {
    const [key, value] = pair.split(": ").map((str) => str.trim());
    jsonObject[key] = isNaN(value) ? value : parseFloat(value); // Parse as number if applicable
  });
  return jsonObject;
};

const InputListRow = ({ input, onShowRelatedData }) => {
  const handleCopy = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
    } catch (err) {
      console.error("Failed to copy:", err);
    }
  };

  const queries = input.queries || [];
  const processedQueries = queries.filter(
    (q) => q.status === "processed"
  ).length;
  const totalQueries = queries.length;

  const notes = transformToJSON(input?.note ?? "");

  return (
    <TableRow hover>
      <TableCell>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <IconButton
            size="small"
            onClick={() => onShowRelatedData(input.queries || [])}
          >
            <OpenInNew fontSize="small" />
          </IconButton>
          <Box sx={{ ml: 1 }}>
            {processedQueries > 0 && (
              <Chip
                label={processedQueries}
                size="small"
                color="success"
                sx={{ mr: 0.5 }}
              />
            )}
            {totalQueries - processedQueries > 0 && (
              <Chip
                label={totalQueries - processedQueries}
                size="small"
                color="default"
              />
            )}
          </Box>
        </Box>
      </TableCell>
      <TableCell>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <IconButton size="small" onClick={() => handleCopy(input.input_id)}>
            <ContentCopy fontSize="small" />
          </IconButton>
          <Typography sx={{ ml: 1 }}>
            {input.input_id.substr(0, 8)}...
          </Typography>
        </Box>
      </TableCell>
      <TableCell>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {input.type === "twitter_trend" ? (
            <Twitter  sx={{ color: "#808080", mr: 1 }} />
          ) : input.type === "google_trend" ? (
            <Google  sx={{ color: "#808080", mr: 1 }} />
          ) : input.type === "news" ? (
            <Feed sx={{ color: "#808080", mr: 1 }} />
          ) : input.type === "asknews" ? (
            <>AskNews</>
          ) : (
            "-"
          )}
          {notes?.trend && (
            <>
              <Typography variant="caption" color="text.secondary">
                {": "}
              </Typography>
              <Typography
                variant="caption"
                color="text.secondary"
                sx={{ marginLeft: 1 }}
              >
                {notes.trend}
              </Typography>
            </>
          )}
            { input.type === "google_trend" && input?.note && (
            <>
              <Typography variant="caption" color="text.secondary">
                {": "}
              </Typography>
              <Typography
                variant="caption"
                color="text.secondary"
                sx={{ marginLeft: 1 }}
              >
                {input?.note}
              </Typography>
            </>
          )}
        </Box>
        {notes?.rank && (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <StarBorder  sx={{ color: "#808080", mr: 1 }} />
            <Typography variant="caption" color="text.secondary">
              {": "}
            </Typography>
            <Typography
              variant="caption"
              color="text.secondary"
              sx={{ marginLeft: 1 }}
            >
              {notes.rank}
            </Typography>
          </Box>
        )}
        {notes?.postCount && (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <FormatListNumbered  sx={{ color: "#808080", mr: 1 }} />
            <Typography variant="caption" color="text.secondary">
              {": "}
            </Typography>
            <Typography
              variant="caption"
              color="text.secondary"
              sx={{ marginLeft: 1 }}
            >
              {notes.postCount}
            </Typography>
          </Box>
        )}
      </TableCell>
      <TableCell>
        <Chip
          label={
            suitabilityLabels.hasOwnProperty(input.suitability_score)
              ? suitabilityLabels[input.suitability_score]
              : input.suitability_score || "N.A."
          }
          color={
            suitabilityColors.hasOwnProperty(input.suitability_score)
              ? suitabilityColors[input.suitability_score]
              : "default"
          }
          size="small"
        />
      </TableCell>
      <TableCell colSpan={4}>
        <TruncatedText text={input.text || "-"} type={input.type} />
      </TableCell>
      <TableCell>
        <Typography variant="caption" color="text.secondary">
          {new Date(input.created_at).toLocaleString()}
        </Typography>
      </TableCell>
    </TableRow>
  );
};

export default InputListRow;
