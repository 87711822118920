import axios from "axios";

export const api = axios.create({
  baseURL: "/api",
});

api.interceptors.request.use((config) => {
  const storedUser = localStorage.getItem("user");
  if (storedUser) {
    const { token } = JSON.parse(storedUser);
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
  }
  return config;
});

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (
      error.response?.status === 401 &&
      error.response.data?.error === "Token expired"
    ) {
      try {
        const storedUser = localStorage.getItem("user");
        if (storedUser) {
          const { token } = JSON.parse(storedUser);

          const response = await api.post(
            "/refresh",
            {},
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );

          const newUserData = {
            ...JSON.parse(storedUser),
            token: response.data.token,
            expiresAt: new Date(response.data.expires_at).getTime(),
          };

          localStorage.setItem("user", JSON.stringify(newUserData));
          api.defaults.headers.Authorization = `Bearer ${response.data.token}`;

          return api(error.config);
        }
      } catch (refreshError) {
        console.error("Token refresh failed:", refreshError);
        localStorage.removeItem("user");
      }
    }

    return Promise.reject(error);
  }
);

const mockMode = false; // Set this to true to enable mock mode
const mockDelay = 10;

const perPage = 25;

import mockEvents from "./mockEvents.json";

export const fetchEvents = async (page = 1, filters = {}) => {
  if (mockMode) {
    console.info(
      `GET /events: {page: ${page}, filters: ${JSON.stringify(filters)}}`
    );
    // Simulate filtering logic for mock data
    let filteredEvents = mockEvents;
    if (filters.reactions && filters.reactions.length > 0) {
      filteredEvents = filteredEvents.filter((event) =>
        filters.reactions.some(
          (reaction) => event.feedback?.rating?.[reaction]?.length > 0
        )
      );
    }
    if (filters.issues && filters.issues.length > 0) {
      filteredEvents = filteredEvents.filter((event) =>
        filters.issues.some(
          (issue) => event.feedback?.issues?.[issue]?.length > 0
        )
      );
    }
    if (filters.changes && filters.changes.length > 0) {
      filteredEvents = filteredEvents.filter((event) => event.changes);
    }
    if (filters.has_comments) {
      filteredEvents = filteredEvents.filter(
        (event) => Object.keys(event.feedback?.comments || {}).length > 0
      );
    }
    if (filters.has_reasoning) {
      filteredEvents = filteredEvents.filter(
        (event) => event.event_metadata?.reasoning
      );
    }
    if (filters.time_frame) {
      const now = new Date();
      filteredEvents = filteredEvents.filter((event) => {
        const eventDate = new Date(event.targeted_date);
        return filters.time_frame === "past"
          ? eventDate < now
          : eventDate > now;
      });
    }
    await new Promise((resolve) => setTimeout(resolve, mockDelay)); // Implement mock delay
    return {
      current_page: page,
      events: filteredEvents.slice((page - 1) * perPage, page * perPage),
      pages: Math.ceil(filteredEvents.length / perPage),
      total: filteredEvents.length,
    };
  } else {
    const params = new URLSearchParams();
    params.append("page", page.toString());

    if (filters.reactions && filters.reactions.length > 0) {
      filters.reactions.forEach((reaction) =>
        params.append("reactions[]", reaction)
      );
    }

    if (filters.issues && filters.issues.length > 0) {
      filters.issues.forEach((issue) => params.append("issues[]", issue));
    }

    if (filters.changes && filters.changes.length > 0) {
      filters.changes.forEach((change) => params.append("changes[]", change));
    }

    if (filters.event_time_frame && filters.event_time_frame.length > 0) {
      filters.event_time_frame.forEach((event_time_frame) =>
        params.append("event_time_frame[]", event_time_frame)
      );
    }

    if (filters.has_comments) {
      params.append("has_comment", filters.has_comments);
    }

    if (filters.has_reasoning) {
      params.append("has_reasoning", filters.has_reasoning);
    }

    if (filters.time_frame) {
      params.append("time_frame", filters.time_frame);
    }

    if (filters.controversy_range) {
      params.append("controversy_min", filters.controversy_range[0]);
      params.append("controversy_max", filters.controversy_range[1]);
    }

    if (filters.popularity_range) {
      params.append("popularity_min", filters.popularity_range[0]);
      params.append("popularity_max", filters.popularity_range[1]);
    }

    if (filters.probability_range) {
      params.append("probability_min", filters.probability_range[0]);
      params.append("probability_max", filters.probability_range[1]);
    }

    if (filters.created_at_filter) {
      params.append("created_at_filter", filters.created_at_filter);
    }

    if (filters.published) {
      params.append("published", filters.published);
    }

    if (filters.resolved) {
      params.append("resolved", filters.resolved);
    }

    if (filters.source) {
      params.append("source", filters.source);
    }

    if (filters.filter_rating && filters.filter_rating.length > 0) {
      filters.filter_rating.forEach((filter_rating) =>
        params.append("filter_rating", filter_rating)
      );
    }

    const response = await api.get(`/events?${params}`);
    return response.data;
  }
};

export const updateEventFeedback = async (eventId, data) => {
  if (mockMode) {
    console.info(`POST /events/${eventId}/feedback: ${JSON.stringify(data)}`);
    const eventIndex = mockEvents.findIndex(
      (event) => event.event_id === eventId
    );
    if (eventIndex !== -1) {
      const event = mockEvents[eventIndex];
      const { feedback } = event;
      const userId = data.user_id;

      // Update Rating
      if (data.rating !== undefined) {
        // Remove existing user rating
        Object.keys(feedback.rating).forEach((ratingKey) => {
          feedback.rating[ratingKey] = feedback.rating[ratingKey].filter(
            (id) => id !== userId
          );
          if (feedback.rating[ratingKey].length === 0) {
            delete feedback.rating[ratingKey];
          }
        });
        // Add new rating
        if (feedback.rating[data.rating]) {
          feedback.rating[data.rating].push(userId);
        } else {
          feedback.rating[data.rating] = [userId];
        }
      }

      // Update Issues
      if (data.issues) {
        data.issues.forEach((issue) => {
          if (!feedback.issues[issue]) {
            feedback.issues[issue] = [];
          }
          if (!feedback.issues[issue].includes(userId)) {
            feedback.issues[issue].push(userId);
          }
        });
        // Remove user from issues no longer reported
        Object.keys(feedback.issues).forEach((issueKey) => {
          if (!data.issues.includes(issueKey)) {
            feedback.issues[issueKey] = feedback.issues[issueKey].filter(
              (id) => id !== userId
            );
            if (feedback.issues[issueKey].length === 0) {
              delete feedback.issues[issueKey];
            }
          }
        });
      }

      // Update Comment
      if (data.comment !== undefined) {
        if (data.comment) {
          feedback.comments[userId] = data.comment;
        } else {
          delete feedback.comments[userId];
        }
      }

      // Update average rating
      const allRatings = Object.entries(feedback.rating).flatMap(
        ([rating, users]) => Array(users.length).fill(parseInt(rating))
      );
      feedback.rating_average = allRatings.length
        ? allRatings.reduce((a, b) => a + b, 0) / allRatings.length
        : null;

      mockEvents[eventIndex].feedback = feedback;
      await new Promise((resolve) => setTimeout(resolve, mockDelay)); // Implement mock delay
      return mockEvents[eventIndex];
    }
    throw new Error("Event not found");
  } else {
    const response = await api.post(`/events/${eventId}/feedback`, data);
    return response.data;
  }
};

export const fetchInputs = async (page = 1) => {
  // if (mockMode) {
  //   // Mock inputs data if needed
  //   return { inputs: [], total: 0 };
  // } else {
  const response = await api.get(`/inputs?page=${page}`);
  return response.data;
  // }
};

export const fetchEventResolvers = async (page = 1, filters = {}) => {
  const params = new URLSearchParams();
  params.append("page", page.toString());
  if (filters?.has_resolution)
    params.append("has_resolution", filters.has_resolution);
  if (filters?.perplexity_false_positive)
    params.append(
      "perplexity_false_positive",
      filters.perplexity_false_positive
    );
  if (filters?.serper_false_positive)
    params.append("serper_false_positive", filters.serper_false_positive);
  if (filters?.search) params.append("search", filters.search);

  if (filters?.published) params.append("published", filters.published);
  if (filters?.resolved) params.append("resolved", filters.resolved);
  if (filters?.source) params.append("source", filters.source);

  if (filters?.filter_rating && filters.filter_rating.length > 0) {
    filters.filter_rating.forEach((filter_rating) =>
      params.append("filter_rating", filter_rating)
    );
  }

  const response = await api.get(`/events?resolver=true&${params}`);
  return response.data;
};

export const login = async (email, password) => {
  const response = await api.post("/login", { email, password });
  return response.data;
};

export const register = async (email, password) => {
  const response = await api.post("/register", { email, password });
  return response.data;
};

export const humanResolve = async (eventIds, resolution) => {
  try {
    const response = await api.post(`/human_resolve`, {
      event_ids: eventIds,
      resolution: resolution ? "true" : "false",
    });
    return response.data;
  } catch (error) {
    console.error("Failed to resolve events:", error.response || error.message);
    throw new Error(
      error.response?.data?.error || "Failed to resolve the events."
    );
  }
};

export const publishEvents = async (eventIds) => {
  try {
    const response = await api.post(`/publish_events`, { event_ids: eventIds });
    return response.data;
  } catch (error) {
    console.error("Failed to publish events:", error.response || error.message);
    throw new Error(
      error.response?.data?.error || "Failed to publish the events."
    );
  }
};

export const unpublishEvents = async (eventIds) => {
  try {
    const response = await api.post(`/unpublish_events`, {
      event_ids: eventIds,
    });
    return response.data;
  } catch (error) {
    console.error(
      "Failed to unpublish events:",
      error.response || error.message
    );
    throw new Error(
      error.response?.data?.error || "Failed to unpublish the events."
    );
  }
};
