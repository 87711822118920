export const statusColors = {
    unprocessed: "secondary",
    in_progress: "primary",
    upgraded: "info",
    processed: "success",
    skipped: "warning",
    failed: "error",
  };
  
  export const suitabilityColors = {
    1: "error",
    2: "warning",
    3: "info",
    4: "success",
    5: "success",
  };
  
  export const suitabilityLabels = {
    1: "unsuitable",
    2: "questionable",
    3: "acceptable",
    4: "reliable",
    5: "optimal",
  };
  