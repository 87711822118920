import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  IconButton,
  Box,
  Typography,
  Chip,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  CircularProgress,
} from "@mui/material";
import { ContentCopy, HelpOutline } from "@mui/icons-material";
import { useApp } from "../../contexts/AppContext";
import { humanResolve } from "../../services/api";
import { formatDate } from "../../utils/dateUtils";

function ResolverTable({ data, selectedResolvers, setSelectedResolvers, refetch }) {
  const resolvers = data?.events || [];
  const { user } = useApp();
  const [openDialog, setOpenDialog] = useState(false);
  const [currentEventId, setCurrentEventId] = useState(null);
  const [loading, setLoading] = useState(false);
 
  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const newSelected = resolvers.map((item) => item.event_id);
      setSelectedResolvers(newSelected);
    } else {
      setSelectedResolvers([]);
    }
  };

  const handleSelect = (id) => {
    const selectedSet = new Set(selectedResolvers);
    if (selectedSet.has(id)) {
      selectedSet.delete(id);
    } else {
      selectedSet.add(id);
    }
    setSelectedResolvers(Array.from(selectedSet));
  };

  const isSelected = (id) => selectedResolvers.includes(id);

  const handleCopy = (id) => {
    navigator.clipboard
      .writeText(id)
      .catch((err) => console.error("Failed to copy:", err));
  };

  const handleResolveClick = (eventId, title) => {
    setCurrentEventId({eventId, title});
    setOpenDialog(true);
  };

  const handleResolution = async (resolution) => {
    setLoading(true);
    try {
      await humanResolve([currentEventId?.eventId], resolution);
      setOpenDialog(false);
      refetch();
    } catch (error) {
      console.error("Failed to resolve:", error);
    } finally {
      setLoading(false);
    }
  };

  const getPerplexityEmoji = (details) => {
    return details?.split("").map((symbol, index) => {
      let emoji;
      switch (symbol) {
        case "+":
          emoji = "✅";
          break;
        case "-":
          emoji = "❌";
          break;
        default:
          emoji = "❓";
      }
      return (
        <span key={index} style={{ fontSize: "1.2em", marginRight: "4px" }}>
          {emoji}
          {(index + 1) % 2 === 0 && <br />}
        </span>
      );
    });
  };

  const getLLMEmoji = (details) => {
    const descriptions = {
      Y: "🟢 Confident Yes",
      y: "🟡 Unconfident Yes",
      N: "🔴 Confident No",
      n: "🟠 Unconfident No",
      U: "⚪ Unclear",
    };
    return details?.split("").map((symbol, index) => (
      <Tooltip key={index} title={descriptions[symbol] || "Unknown"}>
        <span
          style={{ fontSize: "1.2em", marginRight: "4px", cursor: "pointer" }}
        >
          {["🟢", "🟡", "🔴", "🟠", "⚪"]["YyNnU".indexOf(symbol)] || "⚪"}
          {(index + 1) % 2 === 0 && <br />}
        </span>
      </Tooltip>
    ));
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Checkbox
                  indeterminate={
                    selectedResolvers.length > 0 &&
                    selectedResolvers.length < resolvers.length
                  }
                  checked={
                    resolvers.length > 0 &&
                    selectedResolvers.length === resolvers.length
                  }
                  onChange={handleSelectAll}
                />
              </TableCell>
              <TableCell>ID</TableCell>
              <TableCell>Created At</TableCell>
              <TableCell>Title</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Filter rating</TableCell>
              <TableCell>Perplexity</TableCell>
              <TableCell>Perplexity Resolution</TableCell>
              <TableCell>Serper</TableCell>
              <TableCell>Serper Resolution</TableCell>
              <TableCell>AskNews Resolution</TableCell>
              <TableCell>Resolution</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {resolvers.map((resolver) => {
              const perplexity = resolver.event_metadata?.resolver?.perplexity;
              const serper =
                resolver.event_metadata?.resolver?.serper;
              const answer =
                resolver.answer === true
                  ? "YES"
                  : resolver.answer === false
                  ? "NO"
                  : "WAITING FOR RESOLUTION";
              const asknews =
                  resolver.event_metadata?.resolver?.asknews;

              return (
                <TableRow
                  key={resolver.event_id}
                  selected={isSelected(resolver.event_id)}
                >
                  <TableCell>
                    <Checkbox
                      checked={isSelected(resolver.event_id)}
                      onChange={() => handleSelect(resolver.event_id)}
                    />
                  </TableCell>
                  <TableCell>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <IconButton
                        size="small"
                        onClick={() => handleCopy(resolver.event_id)}
                      >
                        <ContentCopy fontSize="small" />
                      </IconButton>
                      <Typography sx={{ ml: 1 }}>
                        {resolver.event_id.substr(0, 8)}...
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell sx={{ verticalAlign: "center" }}>
                    {formatDate(resolver.created_at)}
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2">{resolver.title}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2">
                      {resolver.description}
                    </Typography>
                  </TableCell>
                  <TableCell>
                  <Typography variant="body2">
                      {resolver.event_metadata?.filter_rating || "-"}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    {perplexity ? (
                      <Box>{getPerplexityEmoji(perplexity.statistic)}</Box>
                    ) : (
                      "-"
                    )}
                  </TableCell>
                  <TableCell>
                    <Chip
                      label={perplexity?.resolution || "-"}
                      color={
                        perplexity?.resolution === "YES"
                          ? "success"
                          : perplexity?.resolution === "NO"
                          ? "error"
                          : "warning"
                      }
                      size="small"
                    />
                  </TableCell>
                  <TableCell>
                    {serper ? <Box>{getLLMEmoji(serper.statistic)}</Box> : "-"}
                  </TableCell>
                  <TableCell>
                    <Chip
                      label={serper?.resolution || "-"}
                      color={
                        serper?.resolution === "YES"
                          ? "success"
                          : serper?.resolution === "NO"
                          ? "error"
                          : serper?.resolution === "UNCLEAR"
                          ? "warning"
                          : "transparent"
                      }
                      size="small"
                    />
                  </TableCell>
                  <TableCell>
                    <Chip
                      label={asknews?.resolution || "-"}
                      color={
                        asknews?.resolution === "YES"
                          ? "success"
                          : asknews?.resolution === "NO"
                          ? "error"
                          : asknews?.resolution === "UNCLEAR"
                          ? "warning"
                          : "transparent"
                      }
                      size="small"
                    />
                  </TableCell>

                  <TableCell>
                    {answer === "WAITING FOR RESOLUTION" && user ? (
                      <Tooltip title="Set Resolution">
                        <IconButton
                          color="primary"
                          onClick={() => handleResolveClick(resolver.event_id, resolver.title)}
                        >
                          <HelpOutline />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Chip
                        label={answer}
                        color={
                          answer === "YES"
                            ? "success"
                            : answer === "NO"
                            ? "error"
                            : "transparent"
                        }
                        size="small"
                      />
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "1.5em",
            fontWeight: "bold",
        }}>Set Resolution</DialogTitle>
        <Typography variant="body2" sx={{ px: 4, maxWidth: 300 }}>
            {currentEventId?.title || "-"}
            </Typography>
        <DialogActions sx={{
            display: "flex",
            justifyContent: "space-evenly",
            padding: "24px 24px",
        }}>
          <Button
            variant="contained"
            color="success"
            onClick={() => handleResolution(true)}
            disabled={loading}
          >
            {loading ? <CircularProgress size={20} /> : "✅"}
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => handleResolution(false)}
            disabled={loading}
          >
            {loading ? <CircularProgress size={20} /> : "❌"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ResolverTable;
