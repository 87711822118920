import React, { useEffect, useState } from "react";
import { Alert, CircularProgress, TextField } from "@mui/material";
import ResolverTable from "./ResolverTable";
import { useResolvers } from "../../hooks/useQueries";
import Pagination from "../common/Pagination";
import { useApp } from "../../contexts/AppContext";
import EventResolverFilter from "./EventResolverFilter";
import ResolverActions from "./ResolverActions";

function ResolverList() {
  const [page, setPage] = useState(1);
  const { resolverFilter, updateResolverFilters } = useApp();
  const [selectedResolvers, setSelectedResolvers] = useState([]);

  const { data, error, isLoading, refetch } = useResolvers(
    page,
    resolverFilter
  );

  const [searchTerm, setSearchTerm] = useState(resolverFilter.search || "");

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchTerm !== resolverFilter.search) {
        updateResolverFilters({ search: searchTerm });
      }
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm, resolverFilter.search, updateResolverFilters]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  if (error) {
    return <Alert severity="error">{error.message}</Alert>;
  }

  return (
    <>
      <h1>Event Resolvers</h1>
      <EventResolverFilter
        resolverFilters={resolverFilter}
        updateResolverFilters={updateResolverFilters}
        setPage={setPage}
        total={data?.total || 0}
      />
       <TextField
            label="Search Events"
            variant="outlined"
            size="small"
            value={searchTerm}
            onChange={handleSearchChange}
            placeholder="Search by ID, Title, or Description"
            sx={{ width: "300px", mb: 2 }}
          />
      <ResolverActions
        events={data?.events || []}
        refetch={refetch}
        selectedResolvers={selectedResolvers}
      />
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          <ResolverTable
            data={data}
            selectedResolvers={selectedResolvers}
            setSelectedResolvers={setSelectedResolvers}
            refetch={refetch}
          />
          <Pagination
            page={page}
            setPage={setPage}
            totalPages={data?.pages || 0}
          />
        </>
      )}
    </>
  );
}

export default ResolverList;
