import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import * as api from "../services/api";

export const useEvents = (page = 1, filters = {}) => {
  return useQuery({
    queryKey: ["events", page, filters],
    queryFn: () => api.fetchEvents(page, filters),
    keepPreviousData: true,
    staleTime: 30000, // Consider data fresh for 30 seconds
    refetchInterval: false, // Disable automatic refetching
    refetchOnWindowFocus: false, // Disable refetch on window focus
  });
};

export const useInputs = (page = 1) => {
  return useQuery({
    queryKey: ["inputs", page],
    queryFn: () => api.fetchInputs(page),
    keepPreviousData: true,
    staleTime: 30000,
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });
};

export const useResolvers = (page, filters) => {
  return useQuery({
    queryKey: ["resolvers", page, filters],
    queryFn: () => api.fetchEventResolvers(page, filters),
    keepPreviousData: true,
    staleTime: 30000,
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });
};

export const useUpdateEventFeedback = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ eventId, data }) => api.updateEventFeedback(eventId, data),
    onSuccess: (updatedEvent, { eventId }) => {
      // Update the cache for each matching query
      queryClient.setQueriesData(["events"], (oldData) => {
        if (!oldData) return oldData;

        return {
          ...oldData,
          events: oldData.events.map((event) =>
            event.event_id === eventId
              ? { ...event, feedback: updatedEvent.feedback }
              : event
          ),
        };
      });
    },
  });
};
