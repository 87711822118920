import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Typography,
  Chip,
  Box,
} from "@mui/material";
import { ContentCopy } from "@mui/icons-material";
import { statusColors } from "./utils";

const InputListDialog = ({ open, queries, onClose }) => {
  const handleCopy = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
    } catch (err) {
      console.error("Failed to copy:", err);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Related Queries</DialogTitle>
      <DialogContent>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Category</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Text</TableCell>
                <TableCell>Events</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {queries.map((query) => (
                <TableRow key={query.query_id} hover>
                  <TableCell>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <IconButton size="small" onClick={() => handleCopy(query.query_id)}>
                        <ContentCopy fontSize="small" />
                      </IconButton>
                      <Typography sx={{ ml: 1 }}>
                        {query.query_id.substr(0, 8)}...
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>{query.category || "-"}</TableCell>
                  <TableCell>
                    <Chip label={query.status} color={statusColors[query.status]} size="small" />
                  </TableCell>
                  <TableCell>{query.text || "-"}</TableCell>
                  <TableCell>
                    <Chip label={query.events?.length || 0} size="small" color="default" />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
    </Dialog>
  );
};

export default InputListDialog;
