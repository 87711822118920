import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableContainer,
  Paper,
  Pagination,
  Box,
  Alert,
  TableHead,
  TableRow,
  TableCell,
} from "@mui/material";
import { useInputs } from "../hooks/useQueries";
import { useLoadingWithCountdown } from "../utils/loadingUtils";
import { LoadingOverlay } from "./common/LoadingOverlay";
import InputListRow from "./common/InputList/InputListRow";
import InputListDialog from "./common/InputList/InputListDialog";

const InputList = () => {
  const [page, setPage] = useState(1);
  const [dialogData, setDialogData] = useState({ open: false, queries: [] });
  const { loading, countdown, startLoading, stopLoading } =
    useLoadingWithCountdown(true);
  const { data, error, isLoading } = useInputs(page);

  useEffect(() => {
    if (isLoading) startLoading();
    else stopLoading();
  }, [isLoading]);

  const showRelatedData = (queries) => {
    setDialogData({ open: true, queries });
  };

  const handleDialogClose = () => {
    setDialogData({ open: false, queries: [] });
  };

  if (error) {
    return <Alert severity="error">{error.message}</Alert>;
  }

  return (
    <>
      <TableContainer component={Paper} sx={{ position: "relative" }}>
        {loading && <LoadingOverlay countdown={countdown} />}
        <Table
          sx={{
            tableLayout: "fixed",
            width: "100%",
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell>Actions</TableCell>
              <TableCell>ID</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Suitability </TableCell>
              <TableCell colSpan={4}>Text</TableCell>
              <TableCell>Created At</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.inputs.map((input) => (
              <InputListRow
                key={input.input_id}
                input={input}
                onShowRelatedData={showRelatedData}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <Pagination
          count={data?.pages || 0}
          page={page}
          onChange={(_, value) => setPage(value)}
          color="primary"
        />
      </Box>

      <InputListDialog
        open={dialogData.open}
        queries={dialogData.queries}
        onClose={handleDialogClose}
      />
    </>
  );
};

export default InputList;
