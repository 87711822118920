import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
} from "@mui/material";
import EventRow from "./EventRow";
import { LoadingOverlay } from "../common/LoadingOverlay";

function EventTable({
  loading,
  countdown,
  data,
  userId,
  expandedChanges,
  setExpandedChanges,
  setLoadingEventIds,
  editingCommentId,
  setEditingCommentId,
  commentText,
  setCommentText,
  updateEventFeedback,
  loadingEventIds,
  selectedEvents = [],
  setSelectedEvents,
  setExpandedReasoning,
  expandedReasoning
}) {
  const events = data?.events || [];
  const loadingEventsSet = loadingEventIds || new Set();

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const newSelected = events.map((event) => event.event_id);
      setSelectedEvents(newSelected);
    } else {
      setSelectedEvents([]);
    }
  };

  const handleSelect = (event_id) => {
    const selectedSet = new Set(selectedEvents);
    if (selectedSet.has(event_id)) {
      selectedSet.delete(event_id);
    } else {
      selectedSet.add(event_id);
    }
    setSelectedEvents(Array.from(selectedSet));
  };

  const isSelected = (event_id) => selectedEvents.includes(event_id);

  return (
    <TableContainer component={Paper} sx={{ position: "relative" }}>
      {loading && <LoadingOverlay countdown={countdown} />}
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Checkbox
                indeterminate={
                  selectedEvents.length > 0 &&
                  selectedEvents.length < events.length
                }
                checked={
                  events.length > 0 && selectedEvents.length === events.length
                }
                onChange={handleSelectAll}
              />
            </TableCell>
            {/* <TableCell>ID</TableCell> */}
            <TableCell>Title</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>🌍</TableCell>
            <TableCell>Filter Rating</TableCell>
            <TableCell>Created At</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {events.map((event) => (
            <EventRow
              key={event.event_id}
              event={event}
              userId={userId}
              expandedChanges={expandedChanges}
              setExpandedChanges={setExpandedChanges}
              setLoadingEventIds={setLoadingEventIds}
              editingCommentId={editingCommentId}
              setEditingCommentId={setEditingCommentId}
              commentText={commentText}
              setCommentText={setCommentText}
              updateEventFeedback={updateEventFeedback}
              loadingEventIds={loadingEventsSet}
              setExpandedReasoning={setExpandedReasoning}
              expandedReasoning={expandedReasoning}
              selected={isSelected(event.event_id)}
              onSelect={() => handleSelect(event.event_id)}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default EventTable;
