import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider, createTheme, CssBaseline } from "@mui/material";
import { QueryProvider } from "./providers/QueryProvider";
import { AppProvider, useApp } from "./contexts/AppContext";
import App from "./App";
import "./index.css";

const rootElement = document.getElementById("root");

if (!rootElement) {
  console.error(
    'Root element not found! Make sure index.html contains a div with id="root"'
  );
} else {
  const root = ReactDOM.createRoot(rootElement);

  function ThemedApp() {
    const { darkMode } = useApp();

    const theme = React.useMemo(
      () =>
        createTheme({
          palette: {
            mode: darkMode ? "dark" : "light",
          },
        }),
      [darkMode]
    );

    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <App />
      </ThemeProvider>
    );
  }

  root.render(
    <React.StrictMode>
      <BrowserRouter>
        <AppProvider>
          <QueryProvider>
            <ThemedApp />
          </QueryProvider>
        </AppProvider>
      </BrowserRouter>
    </React.StrictMode>
  );
}
