import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/material";
import { useState } from "react";


const AskNewsText = ({ text }) => {
    text = text.replace(/ ?\[[0-9]{1,2}\]/g, '')
  
    const headlineMatch = text.match(/Headline:\s*(.*?)\s*Takeaways:/);
    const takeawaysMatch = text.match(/Takeaways:\s*(.*?)\s*Story:/);
    const storyMatch = text.match(/Story:\s*([\s\S]*?)(?:\s*Reddit Perspective Summary:|$)/);
    const summaryMatch = text.match(/Reddit Perspective Summary:\s*(.*)/);

    const headline = headlineMatch ? headlineMatch[1] : '';
    let takeaways = '';
    if (takeawaysMatch) {
      const prepTakeaways = takeawaysMatch[1]
        .replace(/', \\"/g, '\', "')
        .replace(/\\", '/g, '", \'')
        .replace(/"/g, '\"')
        .replace(/'/g, '"')
        try {
            const parsedTakeaways = JSON.parse(
              prepTakeaways
            );
            if (Array.isArray(parsedTakeaways)) {
                takeaways = parsedTakeaways.map(t => `${t.trim()}`);
            } else {
                takeaways = takeawaysMatch[1].split("', '").map(t => `${t.trim()}`);
            }
        } catch (e) {
            takeaways = takeawaysMatch[1].replace(/^\[['"]|['"]\]$/g, '')
              .split("', '")
              .join('||||')
              .split('\', "')
              .join('||||')
              .split('", \'')
              .join('||||')
              .split('||||');
        }
    }
    const story = storyMatch ? storyMatch[1] : '';
    const summary = summaryMatch && summaryMatch[1] !== 'None' ? summaryMatch[1] : '';

    return (
      <Box sx={{ position: 'relative' }}>
          <strong>Headline:</strong> {headline}
          <br />
          <br />
          <strong>Takeaways:</strong>
          <ul>
            {takeaways.map((takeaway, index) => (
              <li key={index}>{takeaway}</li>
            ))}
          </ul>
          <br />
          {story && (
            <>
              <strong>Story:</strong> {story}
            </>
          )}
          {summary && (
            <>
              <br />
              <br />
              <strong>Reddit Perspective Summary:</strong> {summary}
            </>
          )}
      </Box>
    )
}

const TruncatedText = ({ text, type }) => {
    const [expanded, setExpanded] = useState(false);

    return (
      <Box sx={{ position: 'relative' }}>
        <Box
          variant="body2"
          sx={{
            width: "90%",
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: expanded ? "none" : 4,
            overflow: expanded ? "visible" : "hidden",
          }}
        >
          {type === 'asknews' ? (
            <AskNewsText text={text} />
          ) : (
            <span>{text}</span>
          )}
        </Box>
        <Box
           sx={{ position: 'absolute',top: '-8px',right: 0 }}
           >
        <IconButton
          size="small"
          onClick={() => setExpanded(!expanded)}
       
        >
          {expanded ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
        </Box>
      </Box>
    );
  };

  export default TruncatedText