import React from "react";
import { Box, Stack, Button } from "@mui/material";
import { useApp } from "../../contexts/AppContext";
import { publishEvents, unpublishEvents } from "../../services/api";

function EventActions({ events, selectedEvents, refetch, setSelectedEvents }) {
  if (selectedEvents.length === 0) return null;
  const { user } = useApp();
  const eventsToPublish = selectedEvents.filter((eventId) => {
    const event = events.find((event) => event.event_id === eventId);
    return event && !event.event_metadata?.published;
  });

  const eventsToUnpublish = selectedEvents.filter((eventId) => {
    const event = events.find((event) => event.event_id === eventId);
    return event && event.event_metadata?.published;
  });

  const handleCopyJson = () => {
    const selectedEventData = events
      .filter((event) => selectedEvents.includes(event.event_id))
      .map((event) => {
        const {
          changes,
          feedback,
          parent_query_id,
          status,
          event_metadata,
          ...rest
        } = event;
        return rest;
      });
    navigator.clipboard.writeText(JSON.stringify(selectedEventData, null, 2));
  };

  const handleCopyPsql = () => {
    const selectedEventData = events
      .filter((event) => selectedEvents.includes(event.event_id))
      .map((event) => {
        const {
          event_id,
          external_id,
          cutoff,
          targeted_date,
          domain,
          answer,
          timestamp,
          title,
          description,
          country,
          resolved_at,
          price,
        } = event;
        return `('${event_id}', '${external_id}', ${cutoff}, ${targeted_date}, ${domain}, ${answer}, ${timestamp}, '${title}', '${description}', '${country}', ${resolved_at}, '${price}')`;
      })
      .join(",\n");
    const psqlInsert = `INSERT INTO event (event_id, external_id, cutoff, targeted_date, domain, answer, timestamp, title, description, country, resolved_at, price) VALUES \n${selectedEventData};`;
    navigator.clipboard.writeText(psqlInsert);
  };

  const handlePublishEvents = async () => {
    try {
      await publishEvents(eventsToPublish);
      setSelectedEvents((prevSelected) =>
        prevSelected.filter((eventId) => !eventsToPublish.includes(eventId))
      );
      refetch();
    } catch (error) {
      console.error("Failed to resolve:", error);
    }
  };

  const handleUnpublishEvents = async () => {
    try {
      await unpublishEvents(eventsToUnpublish);
      setSelectedEvents((prevSelected) =>
        prevSelected.filter((eventId) => !eventsToUnpublish.includes(eventId))
      );
      refetch();
    } catch (error) {
      console.error("Failed to resolve:", error);
    }
  };

  return (
    <Box sx={{ mb: 3 }}>
      <Stack direction="row" spacing={1}>
        <Button variant="outlined" size="small" onClick={handleCopyJson}>
          Copy as JSON ({selectedEvents.length})
        </Button>
        <Button variant="outlined" size="small" onClick={handleCopyPsql}>
          Copy as PSQL ({selectedEvents.length})
        </Button>
        {user &&
          user?.user_metadata?.acl?.publish_events &&
          eventsToPublish.length > 0 && (
            <Button
              variant="outlined"
              size="small"
              onClick={handlePublishEvents}
            >
              Publish Selected ({eventsToPublish.length})
            </Button>
          )}
        {user &&
          user?.user_metadata?.acl?.publish_events &&
          eventsToUnpublish.length > 0 && (
            <Button
              variant="outlined"
              size="small"
              onClick={handleUnpublishEvents}
            >
              Unpublish Selected ({eventsToUnpublish.length})
            </Button>
          )}
      </Stack>
    </Box>
  );
}

export default EventActions;
