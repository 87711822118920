import React, { useState } from "react";
import {
  Box,
  Stack,
  Button,
  Divider,
  Typography,
  Tooltip,
  Drawer,
  FormControlLabel,
  Checkbox,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

const filter_rating = [
  { key: "5", label: "5" },
  { key: "4", label: "4" },
  { key: "3", label: "3" },
  { key: "2", label: "2" },
  { key: "1", label: "1" },
];

function EventResolverFilter({
  resolverFilters,
  updateResolverFilters,
  setPage,
  total,
}) {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open) => {
    setDrawerOpen(open);
  };

  const clearFilters = () => {
    updateResolverFilters({
      has_resolution: true,
      perplexity_false_positive: null,
      serper_false_positive: null,
      resolved: null,
      filter_rating: [],
      source: null,
    });
    setPage(1);
  };

  const handleCheckboxChange = (key) => {
    const currentValue = resolverFilters?.[key] ?? null;
    const newValue = currentValue === null ? true : !currentValue;
    updateResolverFilters({ [key]: newValue });
    setPage(1);
  };

  const handleMultiSelectChange = (key, value) => {
    const currentValue = resolverFilters?.[key] ?? [];
    const newValue = currentValue.includes(value)
      ? currentValue.filter((v) => v !== value)
      : [...currentValue, value];
    updateResolverFilters({ [key]: newValue });
    setPage(1);
  };

  const handleFilterChange = (key, value) => {
    console.log(key, value);
    updateResolverFilters({ [key]: value });
    setPage(1);
  }


  return (
    <>
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        justifyContent={"space-between"}
        sx={{ mb: 2 }}
      >
        <Tooltip title="Filter Resolvers">
          <Button
            variant="contained"
            startIcon={<FilterAltIcon />}
            onClick={() => toggleDrawer(true)}
          >
            Resolver Filters
          </Button>
        </Tooltip>
        <Typography variant="body2" sx={{ mx: 2 }}>
          Total: {total}
        </Typography>
      </Stack>
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={() => toggleDrawer(false)}
      >
        <Box sx={{ width: 300, padding: 2 }}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Resolver Filters
          </Typography>

          <Divider sx={{ my: 2 }} />

          <Typography variant="body2" sx={{ mb: 2 }}>
            Has resolution
          </Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={resolverFilters?.has_resolution || false}
                onChange={() => handleCheckboxChange("has_resolution")}
              />
            }
            label=" At least one"
          />

          <Divider sx={{ my: 2 }} />

          <Typography variant="body2" sx={{ mb: 2 }}>
            Perplexity
          </Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={resolverFilters?.perplexity_false_positive || false}
                onChange={() =>
                  handleCheckboxChange("perplexity_false_positive")
                }
              />
            }
            label=" False Positive"
          />

          <Divider sx={{ my: 2 }} />

          {/* Serper False Positive Filter */}
          <Typography variant="body2" sx={{ mb: 2 }}>
            Serper
          </Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={resolverFilters?.serper_false_positive || false}
                onChange={() => handleCheckboxChange("serper_false_positive")}
              />
            }
            label="False Positive"
          />
          <Divider sx={{ my: 2 }} />
          <Typography variant="subtitle1" sx={{ mb: 1 }}>
            Published
          </Typography>
          <Box sx={{ width: "100%" }}>
            <ToggleButtonGroup
              value={resolverFilters.published || null}
              exclusive
              color="primary"
              onChange={(e, value) => handleFilterChange("published", value)}
              sx={{
                mb: 2,
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <ToggleButton value="true" sx={{ flex: 1 }}>
                Published
              </ToggleButton>
              <ToggleButton value="false" sx={{ flex: 1 }}>
                Unpublished
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
          <Divider sx={{ my: 2 }} />
          <Typography variant="subtitle1" sx={{ mb: 1 }}>
            Resolved
          </Typography>
          <Box sx={{ width: "100%" }}>
            <ToggleButtonGroup
              value={resolverFilters.resolved || null}
              exclusive
              color="primary"
              onChange={(e, value) => handleFilterChange("resolved", value)}
              sx={{
                mb: 2,
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <ToggleButton
                value="true"
                sx={{ flex: 1 }}
              >
                Resolved (has answer)
              </ToggleButton>
              <ToggleButton
                value="false"
                sx={{ flex: 1 }}
              >
                Unresolved (no answer)
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
          <Divider sx={{ my: 2 }} />
          <Typography variant="subtitle1" sx={{ mb: 1 }}>
            Event Source
          </Typography>
          <Box sx={{ width: "100%" }}>
            <ToggleButtonGroup
              value={resolverFilters.source || null}
              exclusive
              color="primary"
              onChange={(e, value) => handleFilterChange("source", value)}
              sx={{
                mb: 2,
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <ToggleButton
                value="manual"
                sx={{ flex: 1 }}
              >
                Manual (since 2024-01-10)
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
          <Divider sx={{ my: 2 }} />
          <Typography variant="subtitle1" sx={{ mb: 1 }}>
            Filter Rating
          </Typography>
          <Stack
            direction="row"
            spacing={1}
            flexWrap="wrap"
            alignContent={"center"}
            justifyContent={"center"}
            sx={{ mb: 2 }}
          >
            {filter_rating.map(({ key, label }) => (
              <Button
                key={label}
                variant={
                  resolverFilters.filter_rating?.includes(label)
                    ? "contained"
                    : "outlined"
                }
                size="small"
                onClick={() => handleMultiSelectChange("filter_rating", label)}
              >
                {key}
              </Button>
            ))}
          </Stack>
          <Divider sx={{ my: 2 }} />
          <Button
            variant="outlined"
            fullWidth
            color="error"
            onClick={clearFilters}
          >
            Clear All Filters
          </Button>
        </Box>
      </Drawer>
    </>
  );
}

export default EventResolverFilter;
