import React, { useState } from "react";
import { Routes, Route, Link, Navigate } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Typography,
  Container,
  Box,
  IconButton,
  useTheme,
  Button,
} from "@mui/material";
import { Brightness4, Brightness7 } from "@mui/icons-material";
import ErrorBoundary from "./components/ErrorBoundary";
import InputList from "./components/InputList";
import EventList from "./components/EventList";
import ComponentShowcase from "./components/library/ComponentShowcase";
import { NotificationSystem } from "./components/common/NotificationSystem";
import { useApp } from "./contexts/AppContext";
import ResolverList from "./components/event/ResolverList";
import LoginRegisterPopup from "./components/auth/LoginRegisterPopup";
import LoginIcon from "@mui/icons-material/Login";
import LogoutIcon from "@mui/icons-material/Logout";

function App() {
  const { darkMode, toggleDarkMode, user, logoutUser } = useApp();
  const [popupOpen, setPopupOpen] = useState(false);

  const handleLoginClick = () => setPopupOpen(true);
  const handleLogoutClick = () => logoutUser();

  return (
    <ErrorBoundary>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
          <Toolbar>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              Event Tracking System
            </Typography>
            <Box sx={{ "& > :not(style)": { m: 1 } }}>
              <Link to="/" style={{ color: "white", textDecoration: "none" }}>
                Events
              </Link>
              {/* <Link
                to="/resolvers"
                style={{ color: "white", textDecoration: "none" }}
              >
                Resolver
              </Link> */}
              <Link
                to="/inputs"
                style={{ color: "white", textDecoration: "none" }}
              >
                Inputs
              </Link>
              <Link
                to="/components"
                style={{ color: "white", textDecoration: "none" }}
              >
                Components
              </Link>

              <IconButton
                sx={{ ml: 1 }}
                onClick={toggleDarkMode}
                color="inherit"
              >
                {darkMode ? <Brightness7 /> : <Brightness4 />}
              </IconButton>
              <Button
                color="inherit"
                startIcon={user ? <LogoutIcon /> : <LoginIcon />}
                onClick={user ? handleLogoutClick : handleLoginClick}
              >
                {user ? "Logout" : "Login"}
              </Button>
            </Box>
          </Toolbar>
        </AppBar>
        <Container maxWidth="xl" sx={{ mt: 4 }}>
          <Routes>
            <Route path="/" element={<EventList />} />
            <Route path="/events" element={<Navigate to="/" />} />
            <Route path="/resolvers" element={<ResolverList />} />
            <Route path="/inputs" element={<InputList />} />
            <Route path="/components" element={<ComponentShowcase />} />
          </Routes>
        </Container>
        <NotificationSystem />
        <LoginRegisterPopup
          open={popupOpen}
          onClose={() => setPopupOpen(false)}
        />
      </Box>
    </ErrorBoundary>
  );
}

export default App;
