import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Tab,
  Tabs,
  Box,
} from "@mui/material";
import { login,register } from "../../services/api";
import { useApp } from "../../contexts/AppContext";

function LoginRegisterPopup({ open, onClose }) {
  const [tab, setTab] = useState(0); 
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const { loginUser } = useApp();

  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
    setEmail("");
    setPassword("");
    setError(null);
  };

  const handleSubmit = async () => {
    setLoading(true);
    setError(null);
    try {
      const response =
        tab === 0 ? await login(email, password) : await register(email, password);
      if (tab === 0) {
        loginUser(response);
      } else {
        alert("Registration successful! You can now log in.");
      }
      clearForm();
      onClose();
    } catch (err) {
      setError(err.response?.data?.message || "An error occurred.");
    } finally {
      setLoading(false);
    }
  };

  const clearForm = () => {
    setEmail("");
    setPassword("");
    setError(null);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
      <DialogTitle>{tab === 0 ? "Login" : "Register"}</DialogTitle>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={tab} onChange={handleChangeTab} centered>
          <Tab label="Login" />
          <Tab label="Register" />
        </Tabs>
      </Box>
      <DialogContent>
        {error && <p style={{ color: "red" }}>{error}</p>}
        <TextField
          label="Email"
          type="email"
          fullWidth
          margin="normal"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          label="Password"
          type="password"
          fullWidth
          margin="normal"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={loading}>
          Cancel
        </Button>
        <Button onClick={handleSubmit} disabled={loading}>
          {tab === 0 ? "Login" : "Register"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default LoginRegisterPopup;
