import React, { useState } from "react";
import {
  Box,
  Stack,
  Button,
  Divider,
  Typography,
  Tooltip,
  Drawer,
  Slider,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
const reactions = [
  { emoji: "😍", rating: 5 },
  { emoji: "🙂", rating: 4 },
  { emoji: "😐", rating: 3 },
  { emoji: "😕", rating: 2 },
  { emoji: "😡", rating: 1 },
];

const filter_rating = [
  { key: "5", label: "5" },
  { key: "4", label: "4" },
  { key: "3", label: "3" },
  { key: "2", label: "2" },
  { key: "1", label: "1" },
];

const issues = [
  { key: "need_improve_date", label: "need improve date" },
  { key: "hard_to_verify", label: "hard to verify" },
  { key: "too_broad", label: "too broad" },
  { key: "irrelevant", label: "irrelevant" },
  { key: "highly_predictable", label: "highly predictable" },
];

const event_time_frame = [
  { key: "less_7_days", label: "Less then 7 days" },
  { key: "8_14_days", label: "Between 8 and 14 days" },
  { key: "15_31_days", label: "Between 15 and 31 days" },
  { key: "1_2_months", label: "Between 1 and 2 months" },
  { key: "2_plus_months", label: "More then 2 months" },
];

const sliderMarks = [
  { value: 0, label: "0" },
  { value: 2, label: "2" },
  { value: 4, label: "4" },
  { value: 6, label: "6" },
  { value: 8, label: "8" },
  { value: 10, label: "10" },
];

const created_at_filter = [
  { key: "today", label: "today" },
  { key: "last_3_days", label: "Last 3 days" },
  { key: "last_7_days", label: "Last 7 days" },
];

function EventFilters({ filters, updateFilters, setPage, total }) {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open) => {
    setDrawerOpen(open);
  };

  const clearFilters = () => {
    updateFilters("events", {
      reactions: [],
      issues: [],
      changes: [],
      event_time_frame: [],
      has_comments: false,
      time_frame: null,
      controversy_range: null,
      popularity_range: null,
      probability_range: null,
      created_at_filter: null,
      published: null,
      resolved: null,
      filter_rating: [],
      source: null,
    });
    setPage(1);
  };

  const handleSliderChange = (key, newValue) => {
    updateFilters("events", { [key]: newValue });
    setPage(1);
  };

  const handleFilterChange = (filterType, value) => {
    if (filterType === "reactions") {
      const currentReactions = filters.events.reactions || [];
      const updatedReactions = currentReactions.includes(value)
        ? currentReactions.filter((r) => r !== value)
        : [...currentReactions, value];

      updateFilters("events", { reactions: updatedReactions });
    } else if (filterType === "issues") {
      const currentIssues = filters.events.issues || [];
      const updatedIssues = currentIssues.includes(value)
        ? currentIssues.filter((i) => i !== value)
        : [...currentIssues, value];

      updateFilters("events", { issues: updatedIssues });
    } else if (filterType === "changes") {
      const currentChanges = filters.events.changes || [];
      const updatedChanges = currentChanges.includes("changes")
        ? []
        : ["changes"];

      updateFilters("events", { changes: updatedChanges });
    } else if (filterType === "event_time_frame") {
      const currentIssues = filters.events.event_time_frame || [];
      const updatedIssues = currentIssues.includes(value)
        ? currentIssues.filter((i) => i !== value)
        : [value];

      updateFilters("events", { event_time_frame: updatedIssues });
    } else if (filterType === "has_comments") {
      const currentHasComments = filters.events.has_comments;
      const updatedHasComments = !currentHasComments;
      updateFilters("events", { has_comments: updatedHasComments });
    } else if (filterType === "has_reasoning") {
      const currentHasReasoning = filters.events.has_reasoning;
      const updatedHasReasoning = !currentHasReasoning;
      updateFilters("events", { has_reasoning: updatedHasReasoning });
    } else if (filterType === "time_frame") {
      const currentTimeFrame = filters.events.time_frame;
      const updatedTimeFrame = currentTimeFrame === value ? null : value;
      updateFilters("events", { time_frame: updatedTimeFrame });
    } else if (filterType === "created_at_filter") {
      updateFilters("events", { created_at_filter: value });
    } else if (filterType === "published") {
      updateFilters("events", { published: value });
    } else if (filterType === "resolved") {
      updateFilters("events", { resolved: value });
    } else if (filterType === "source") {
      updateFilters("events", { source: value });
    } else if (filterType === "filter_rating") {
      const currentRating = filters.events.filter_rating || [];
      const updatedRating = currentRating.includes(value)
        ? currentRating.filter((r) => r !== value)
        : [...currentRating, value];

      updateFilters("events", { filter_rating: updatedRating });
    }

    setPage(1);
  };

  return (
    <>
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        justifyContent={"space-between"}
        sx={{ mb: 2 }}
      >
        <Tooltip title="Filter Events">
          <Button
            variant="contained"
            startIcon={<FilterAltIcon />}
            onClick={() => toggleDrawer(true)}
          >
            Filters
          </Button>
        </Tooltip>
        <Typography variant="body2" sx={{ mx: 2 }}>
          Total: {total}
        </Typography>
      </Stack>
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={() => toggleDrawer(false)}
      >
        <Box sx={{ width: 300, padding: 2 }}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Event Filters
          </Typography>
          <Typography variant="subtitle1" sx={{ mb: 1 }}>
            Reactions
          </Typography>
          <Stack
            direction="row"
            spacing={1}
            flexWrap="wrap"
            alignContent={"center"}
            justifyContent={"center"}
            sx={{ mb: 2 }}
          >
            {reactions.map(({ emoji, rating }) => (
              <Button
                key={rating}
                variant={
                  filters.events.reactions?.includes(rating)
                    ? "contained"
                    : "outlined"
                }
                size="small"
                onClick={() => handleFilterChange("reactions", rating)}
              >
                {emoji}
              </Button>
            ))}
          </Stack>
          <Divider sx={{ my: 2 }} />
          {/* Issues */}
          <Typography variant="subtitle1" sx={{ mb: 1 }}>
            Issues
          </Typography>
          <Stack direction="column" flexWrap="wrap" sx={{ mb: 2 }}>
            {issues.map(({ key, label }) => (
              <Button
                key={key}
                variant={
                  filters.events.issues?.includes(key)
                    ? "contained"
                    : "outlined"
                }
                sx={{
                  marginBottom: 1,
                }}
                size="small"
                onClick={() => handleFilterChange("issues", key)}
              >
                {label}
              </Button>
            ))}
          </Stack>
          {/* Extra Data */}
          <Divider sx={{ my: 2 }} />
          <Typography variant="subtitle1" sx={{ mb: 1 }}>
            Extra Data
          </Typography>
          <Stack direction="column" flexWrap="wrap">
            <Button
              variant={
                (filters.events.changes || []).includes("changes")
                  ? "contained"
                  : "outlined"
              }
              size="small"
              sx={{
                marginBottom: 1,
              }}
              onClick={() => handleFilterChange("changes", "changes")}
            >
              Has Changes
            </Button>
            <Button
              variant={filters.events.has_reasoning ? "contained" : "outlined"}
              size="small"
              sx={{
                marginBottom: 1,
              }}
              onClick={() => handleFilterChange("has_reasoning")}
            >
              Has Reasoning
            </Button>
            <Button
              sx={{
                marginBottom: 1,
              }}
              variant={filters.events.has_comments ? "contained" : "outlined"}
              size="small"
              onClick={() => handleFilterChange("has_comments")}
            >
              Has Comments
            </Button>
            {/* Time Frame */}
            <Divider sx={{ my: 2 }} />
            <Typography variant="subtitle1" sx={{ mb: 1 }}>
              Time Frame
            </Typography>
            <Button
              variant={
                filters.events.time_frame === "past" ? "contained" : "outlined"
              }
              size="small"
              sx={{
                marginBottom: 1,
              }}
              onClick={() => handleFilterChange("time_frame", "past")}
            >
              Occurred Events
            </Button>
            <Button
              variant={
                filters.events.time_frame === "future"
                  ? "contained"
                  : "outlined"
              }
              sx={{
                marginBottom: 1,
              }}
              size="small"
              onClick={() => handleFilterChange("time_frame", "future")}
            >
              Upcoming Events
            </Button>
          </Stack>
          <Divider sx={{ my: 2 }} />
          <Typography variant="subtitle1" sx={{ mb: 1 }}>
            Published
          </Typography>
          <Box sx={{ width: "100%" }}>
            <ToggleButtonGroup
              value={filters.events.published || null}
              exclusive
              color="primary"
              onChange={(e, value) => handleFilterChange("published", value)}
              sx={{
                mb: 2,
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <ToggleButton
                value="true"
                sx={{ flex: 1 }}
              >
                Published
              </ToggleButton>
              <ToggleButton
                value="false"
                sx={{ flex: 1 }}
              >
                Unpublished
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
          <Divider sx={{ my: 2 }} />
          <Typography variant="subtitle1" sx={{ mb: 1 }}>
            Resolved
          </Typography>
          <Box sx={{ width: "100%" }}>
            <ToggleButtonGroup
              value={filters.events.resolved || null}
              exclusive
              color="primary"
              onChange={(e, value) => handleFilterChange("resolved", value)}
              sx={{
                mb: 2,
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <ToggleButton
                value="true"
                sx={{ flex: 1 }}
              >
                Resolved (has answer)
              </ToggleButton>
              <ToggleButton
                value="false"
                sx={{ flex: 1 }}
              >
                Unresolved (no answer)
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
          <Divider sx={{ my: 2 }} />
          <Typography variant="subtitle1" sx={{ mb: 1 }}>
            Event Source
          </Typography>
          <Box sx={{ width: "100%" }}>
            <ToggleButtonGroup
              value={filters.events.source || null}
              exclusive
              color="primary"
              onChange={(e, value) => handleFilterChange("source", value)}
              sx={{
                mb: 2,
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <ToggleButton
                value="manual"
                sx={{ flex: 1 }}
              >
                Manual (since 2024-01-10)
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
          <Divider sx={{ my: 2 }} />
          <Typography variant="subtitle1" sx={{ mb: 1 }}>
            Filter Rating
          </Typography>
          <Stack
            direction="row"
            spacing={1}
            flexWrap="wrap"
            alignContent={"center"}
            justifyContent={"center"}
            sx={{ mb: 2 }}
          >
            {filter_rating.map(({ key, label }) => (
              <Button
                key={label}
                variant={
                  filters.events.filter_rating?.includes(label)
                    ? "contained"
                    : "outlined"
                }
                size="small"
                onClick={() => handleFilterChange("filter_rating", label)}
              >
                {key}
              </Button>
            ))}
          </Stack>
          {/* Event Duration */}
          <Divider sx={{ my: 2 }} />
          <Typography variant="subtitle1" sx={{ mb: 1 }}>
            Event Duration
          </Typography>
          <Stack direction="column" flexWrap="wrap">
            {event_time_frame.map(({ key, label }) => (
              <Button
                key={key}
                variant={
                  filters.events.event_time_frame?.includes(key)
                    ? "contained"
                    : "outlined"
                }
                sx={{
                  marginBottom: 1,
                }}
                size="small"
                onClick={() => handleFilterChange("event_time_frame", key)}
              >
                {label}
              </Button>
            ))}
          </Stack>

          <Divider sx={{ my: 2 }} />

          {/* Created At Filter */}
          <Typography variant="subtitle1" sx={{ mb: 1 }}>
            Created At
          </Typography>
          <Stack direction="column" flexWrap="wrap">
            {created_at_filter.map(({ key, label }) => (
              <Button
                key={key}
                variant={
                  filters.events.created_at_filter === key
                    ? "contained"
                    : "outlined"
                }
                sx={{
                  marginBottom: 1,
                }}
                size="small"
                onClick={() => handleFilterChange("created_at_filter", key)}
              >
                {label}
              </Button>
            ))}
          </Stack>
          <Typography variant="subtitle1">Popularity Rating</Typography>
          <Slider
            value={filters.events.popularity_range || [0, 10]}
            onChange={(e, newValue) =>
              handleSliderChange("popularity_range", newValue)
            }
            valueLabelDisplay="auto"
            min={0}
            max={10}
            sx={{ mb: 2 }}
            marks={sliderMarks}
          />
          <Typography variant="subtitle1">Controversy Rating</Typography>
          <Slider
            value={filters.events.controversy_range || [0, 10]}
            onChange={(e, newValue) =>
              handleSliderChange("controversy_range", newValue)
            }
            valueLabelDisplay="auto"
            min={0}
            max={10}
            sx={{ mb: 2 }}
            marks={sliderMarks}
          />
          <Typography variant="subtitle1">Probability of Yes</Typography>
          <Slider
            value={filters.events.probability_range || [0, 10]}
            onChange={(e, newValue) =>
              handleSliderChange("probability_range", newValue)
            }
            valueLabelDisplay="auto"
            min={0}
            max={10}
            sx={{ mb: 2 }}
            marks={sliderMarks}
          />

          {/* Clear All Filters */}
          <Divider sx={{ my: 2 }} />
          <Button
            variant="outlined"
            fullWidth
            color="error"
            onClick={clearFilters}
          >
            Clear All Filters
          </Button>
        </Box>
      </Drawer>
    </>
  );
}

export default EventFilters;
