import React, { useState } from 'react';
import {Alert} from "@mui/material";
import { useEvents, useUpdateEventFeedback } from '../hooks/useQueries';
import { useLoadingWithCountdown } from '../utils/loadingUtils';
import { useApp } from '../contexts/AppContext';
import EventFilters from './event/EventFilters';
import EventActions from './event/EventActions';
import EventTable from './event/EventTable';
import Pagination from './common/Pagination';
import { v4 as uuidv4 } from 'uuid';
import Cookies from 'js-cookie';

function EventList() {
  const [userId, setUserId] = useState(null);
  const [page, setPage] = useState(1);
  const [editingCommentId, setEditingCommentId] = useState(null);
  const [commentText, setCommentText] = useState('');
  const [expandedChanges, setExpandedChanges] = useState(new Set());
  const [expandedReasoning, setExpandedReasoning] = useState(new Set());
  const [loadingEventIds, setLoadingEventIds] = useState(new Set());
  const [selectedEvents, setSelectedEvents] = useState([]);
  const { filters, updateFilters } = useApp();
  const { loading, countdown, startLoading, stopLoading } = useLoadingWithCountdown(true);
  const { data, error, isLoading, refetch } = useEvents(page, filters.events);
  const updateEventFeedback = useUpdateEventFeedback();

  React.useEffect(() => {
    let id = Cookies.get('user_id');
    if (!id) {
      id = uuidv4();
      Cookies.set('user_id', id, { expires: 365 });
    }
    setUserId(id);
  }, []);

  React.useEffect(() => {
    if (isLoading) {
      startLoading();
    } else {
      stopLoading();
    }
  }, [isLoading]);

  if (error) {
    return <Alert severity="error">{error.message}</Alert>;
  }

  return (
    <>
      <EventFilters
        filters={filters}
        updateFilters={updateFilters}
        setPage={setPage}
        total={data?.total || 0}
      />
      <EventActions
        events={data?.events || []}
        selectedEvents={selectedEvents}
        setSelectedEvents={setSelectedEvents}
        refetch={refetch}
      />
      <EventTable
        loading={loading}
        countdown={countdown}
        userId={userId}
        expandedChanges={expandedChanges}
        setExpandedChanges={setExpandedChanges}
        expandedReasoning={expandedReasoning}
        setExpandedReasoning={setExpandedReasoning}
        loadingEventIds={loadingEventIds}
        setLoadingEventIds={setLoadingEventIds}
        editingCommentId={editingCommentId}
        setEditingCommentId={setEditingCommentId}
        commentText={commentText}
        setCommentText={setCommentText}
        data={data}
        updateEventFeedback={updateEventFeedback}
        selectedEvents={selectedEvents}
        setSelectedEvents={setSelectedEvents}
      />
      <Pagination
        page={page}
        setPage={setPage}
        totalPages={data?.pages || 0}
      />
    </>
  );
}

export default EventList;
